// ------------------ Modal (static)
/* eslint-disable-next-line no-unused-vars */
const Modal = (() => {
	// private var(s)

	// private method(s)
	const _constructor = () => {};

	// Bind Events in Modal
	const _bindModalEvents = ($el) => {
		const hash = window.location.hash.split('#').pop();
		const $elData = $el.data('model');
		const $elId = $elData.anchor;

		// Find buttons that launch modal windows via anchor ID
		$.each($el.find('button.bat-modal-close'), (index, $button) => {
			$($button).click(() => {
				const targetId = $($button).data('parameters').replace('#', '');
				const modalContent = $el.find('.bat-modal-content');
				modalContent.addClass('fade-out');
				setTimeout(() => {
					$(`#${targetId}`)
						.removeClass('active')
						.trigger('modal:close');
					modalContent.removeClass('fade-out');
				}, 200);
			});
		});

		// Event listeners
		$el.on('modal:open', () => {
			// console.log('modal open');
			/* eslint-disable-next-line no-undef */
			Utils.lockBody();
		});

		$el.on('modal:close', () => {
			// console.log('moda closed');
			/* eslint-disable-next-line no-undef */
			Utils.unlockBody();
		});

		// Auto open modal if element id matches hash is in the url
		if (hash === $elId) {
			$(`#${$elId}`).addClass('active').trigger('modal:open');
		}
	};

	_constructor();

	// output/public
	return {
		bindModalEvents: _bindModalEvents,
	};
})();
