// ------------------ brand (static)
/* eslint-disable-next-line no-unused-vars */
const BrandForm = (() => {
	// private var(s)
	let isGoogleMapsLoaded = false;

	// private method(s)
	const _constructor = () => {
		// console.log('Brand is running');
	};

	// Bind Events
	const _bindEvents = () => {
		// console.log('Bind Events Here');
	};

	// select around custom dopdown
	const _focusSelect = ($forms) => {
		$forms.find('select').each((index, select) => {
			const $field = $(select);

			$field.on('focus', () => {
				$field.parent('.select-wrapper').addClass('wrapper-focused');
			});

			$field.on('change focusout', async () => {
				$field.parent('.select-wrapper').removeClass('wrapper-focused');
			});
		});
	};

	const _toolTip = ($forms) => {
		const $toolTipIcon = $('.tooltip-trigger');
		const $toolTipContent = $('.tooltip-content');
		const $toolTipClose = $('.tooltip-close');

		$forms.find('.tooltip').each(() => {
			$toolTipIcon.on('click', () => {
				$toolTipContent.addClass('active');
			});
			$toolTipClose.on('click', () => {
				$toolTipContent.removeClass('active');
			});
		});
	};

	const _setCalendarRange = (dp) => {
		$(dp).attr('min', '1910-01-01');
		$(dp).attr('max', new Date().toISOString().slice(0, -14));
		$(dp).on('change', function changedate() {
			const dateSelected = $(this).val();
			$(this).attr('value', dateSelected);
		});
	};

	// Toggle password field
	const _togglePassWordField = () => {
		$('.toggle-visibility').on('click', function togglePwVisibility(e) {
			e.preventDefault();
			if ($(this).siblings('input').attr('type') === 'password') {
				$(this).siblings('input').attr('type', 'text');
				$(this).css(
					'background-image',
					'url("/content/dam/gtr/images/global/icons-sprite.svg#eye-off")'
				);
			} else {
				$(this).siblings('input').attr('type', 'password');
				$(this).css(
					'background-image',
					'url("/content/dam/gtr/images/global/icons-sprite.svg#eye-on")'
				);
			}
		});
	};

	// Google Maps API
	const _loadGoogleMapsApi = () => {
		// Dont load script if no google key is set in brand config
		if (!CONFIG.googleMapsKey) {
			/* eslint-disable-next-line no-console */
			console.log('No map key available.');
			return false;
		}

		// Create the script tag, set the appropriate attributes
		const script = document.createElement('script');
		script.src = `https://maps.googleapis.com/maps/api/js?key=${CONFIG.googleMapsKey}&callback=initMap`;
		script.async = true;

		// Attach your callback function to the `window` object
		window.initMap = () => {
			isGoogleMapsLoaded = true;

			/* eslint-disable-next-line no-console */
			console.log('BrandForms.loadGoogleMapsAPI init');
		};

		document.head.appendChild(script);

		return true;
	};

	// Google reCaptcha
	const _recaptchaSiteKey = $('body').data('site-key');

	const _getCityState = async (requestObject) => {
		const geocoder = new google.maps.Geocoder();
		const zipCode = requestObject.postal_code;
		const addressComponents = {};

		await geocoder.geocode({ address: `${zipCode}` }, (results, status) => {
			if (status === 'OK') {
				const addressArray = results[0].formatted_address.split(', ');

				const [city, stateAndZip] = addressArray;
				const stateAndZipArray = stateAndZip.split(' ');
				const [state] = stateAndZipArray;

				addressComponents.city = city;
				addressComponents.state = state;
			}
		});

		return addressComponents;
	};

	const _bindZipcode = ($zipCode, $city, $state) => {
		const zipRegex = /^\d{5}$/;
		const zipCodeValue = $zipCode.val();

		const requestObject = {
			postal_code: zipCodeValue,
			key: CONFIG.googleMapsKey,
			country: 'US',
		};

		// Dont bind if no google key is set in brand config
		if (!CONFIG.googleMapsKey || !isGoogleMapsLoaded) {
			return false;
		}

		if (zipRegex.test(zipCodeValue)) {
			_getCityState(requestObject).then((response) => {
				if (response && response.city) {
					$city.val(response.city).trigger('focus').trigger('blur');
				} else {
					$city.val('').trigger('focus').trigger('blur');
				}

				if (response && response.state) {
					$state
						.val(response.state)
						.trigger('focus')
						.trigger('change')
						.trigger('blur');
				} else {
					$state
						.find(`option[value='']`)
						.attr('selected', 'selected')
						.trigger('change')
						.trigger('blur');
				}
			});
		}

		return true;
	};

	const _autoInsertDashes = (phoneNumberInput) => {
		phoneNumberInput.addEventListener(
			'keydown',
			function onPhoneNumberInput(e) {
				if (e.key === 'Backspace' || e.key === 'Delete') return;
				if (e.target.value.length === 3 && e.key !== '-') {
					phoneNumberInput.value += '-';
				}
				if (e.target.value.length === 7 && e.key !== '-') {
					phoneNumberInput.value += '-';
				}
			}
		);
	};

	_constructor();

	// output/public
	return {
		autoInsertDashes: _autoInsertDashes,
		bindEvents: _bindEvents,
		bindZipcode: _bindZipcode,
		focusSelect: _focusSelect,
		getCityState: _getCityState,
		loadGoogleMapsApi: _loadGoogleMapsApi,
		recaptchaSiteKey: _recaptchaSiteKey,
		setCalendarRange: _setCalendarRange,
		togglePassWordField: _togglePassWordField,
		toolTip: _toolTip,
	};
})();
