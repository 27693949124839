// ------------------ Forms (static)
/* eslint-disable-next-line no-unused-vars */
const Forms = (() => {
	// private var(s)
	let isGoogleMapsLoaded = false;

	// private method(s)
	const _constructor = () => {};

	const _updateIncrementer = (increment, e) => {
		e.preventDefault();
		const fieldName = $(e.currentTarget).data('field');
		const $field = $(e.currentTarget).siblings(`input[name=${fieldName}]`);
		const min = parseInt($field.attr('min'), 10);
		const max = parseInt($field.attr('max'), 10);
		const step = parseInt($field.attr('step'), 10);
		const currentVal = parseInt($field.val(), 10);
		const isNumber = !Number.isNaN(currentVal);

		if (isNumber) {
			// Increment
			if (increment && currentVal < max) {
				$field.val(currentVal + step);
			}

			// Decrement
			if (!increment && currentVal > min) {
				$field.val(currentVal - step);
			}
		} else {
			$field.val(min);
		}

		$field.trigger('change');
	};

	const _bindIncrementer = ($incrementerGroup) => {
		const $field = $incrementerGroup.find('input[type="number"');
		const fieldMinValue = $field.attr('min');
		const fieldMaxValue = $field.attr('max');
		const fieldLoadValue = $field.val();
		const $minusButton = $incrementerGroup.find('.button-minus');
		const $plusButton = $incrementerGroup.find('.button-plus');

		$field.on('change', () => {
			const currentValue = $field.val();

			if (currentValue !== fieldLoadValue) {
				$incrementerGroup.addClass('changed');
			} else {
				$incrementerGroup.removeClass('changed');
			}

			// Disable minus button when hit min input value
			if (currentValue === fieldMinValue) {
				$minusButton.prop('disabled', true);
			} else {
				$minusButton.prop('disabled', false);
			}

			// Disable plus button when hit max input value
			if (currentValue === fieldMaxValue) {
				$plusButton.prop('disabled', true);
			} else {
				$plusButton.prop('disabled', false);
			}
		});

		$incrementerGroup.on('click', '.button-plus', (e) => {
			_updateIncrementer(true, e);
		});

		$incrementerGroup.on('click', '.button-minus', (e) => {
			_updateIncrementer(false, e);
		});

		// Disable minus button if load value is min input value
		if (fieldLoadValue === fieldMinValue)
			$minusButton.prop('disabled', true);

		// Disable plus button if load value is max input value
		if (fieldLoadValue === fieldMaxValue)
			$plusButton.prop('disabled', true);
	};

	const _loadBlockJSON = async (jsonPath) => {
		const request = await fetch(jsonPath)
			.then((response) => response.json())
			.catch((error) => {
				/* eslint-disable-next-line no-console */
				console.log('Request failed: ', error);
			});

		return request;
	};

	const _loadFormJSON = async (componentObject) => {
		const jsonPath = componentObject.formJson;

		const request = await fetch(jsonPath)
			.then((response) => response.json())
			.then(async (formResponse) => {
				if (formResponse) {
					const { steps } = formResponse;

					if (steps && steps.length > 0) {
						// Loop steps
						await Promise.all(
							steps.map(async (step) => {
								const { formBlocks } = step;

								if (formBlocks && formBlocks.length > 0) {
									// Loop Rows
									await Promise.all(
										formBlocks.map(async (block, index) => {
											const { $ref } = block;

											if ($ref) {
												await _loadBlockJSON($ref).then(
													(blockResponse) => {
														formBlocks[index] =
															blockResponse;
													}
												);
											}
										})
									);
								}
							})
						);
					}

					componentObject.form = formResponse;
				}

				return componentObject;
			})
			.catch((error) => {
				/* eslint-disable-next-line no-console */
				console.log('Request failed: ', error);
			});

		return request;
	};

	const _validateField = ($field) => {
		const isRequired = $field.is('[required]');
		const fieldValue = $field.val();
		const pattern = $field.attr('pattern')
			? new RegExp($field.attr('pattern'))
			: '';
		const patternMatch = pattern ? pattern.test(fieldValue) : true;
		const fieldMatch = $field.hasClass('field-match-error');

		if (fieldValue && patternMatch && !fieldMatch) {
			$field.addClass('complete').removeClass('error');
		} else {
			$field.removeClass('complete');

			if (isRequired) {
				$field.addClass('error');

				const eventData = {
					detail: {
						code: $field.attr('name'),
					},
				};

				const errorEvent = new CustomEvent('error', eventData);
				document.dispatchEvent(errorEvent);
			}
		}
	};

	const _validateFieldset = ($fieldset) => {
		

		const inputFields = $($fieldset).find('input');
		const isRequired = $($fieldset).hasClass('required');

		if (isRequired) {
			if (inputFields.is(':checked')) {
				$($fieldset).addClass('complete').removeClass('error');
			} else {
				$($fieldset).addClass('error').removeClass('complete');
			}
		}

		
	};

	const _validateForm = ($form) => {
		const $formSubmitButton = $form.find('button[type=submit]');
		let isFormComplete = true;

		$form
			.find(
				'input:not(:checkbox):not(:radio):not([readonly]), textarea, select, fieldset'
			)
			.not(':hidden')
			.each((index, field) => {
				const $field = $(field);
				const isRequired =
					$field.is('[required]') || $field.hasClass('required');
				const isComplete = $field.hasClass('complete');

				if ($field.hasClass('error') || (isRequired && !isComplete)) {
					isFormComplete = false;
				}
			});

		if (isFormComplete) {
			$formSubmitButton.removeAttr('disabled');
		} else {
			$formSubmitButton.prop('disabled', true);
		}
	};

	const _bindFieldEvents = ($form) => {
		// Inputs text, number, email, textarea
		$form
			.find('input:not(:checkbox):not(:radio), textarea')
			.each((index, input) => {
				const $field = $(input);

				$field.on('focus', () => {
					$field.addClass('active');
					$field.siblings('label').addClass('blurred');
				});

				$field.on('blur', async () => {
					$field.removeClass('active');
					await _validateField($field);
					await _validateForm($form);
				});

				$field.keyup(
					Utils.debounce(async () => {
						await _validateField($field);
						await _validateForm($form);
					}, 1000)
				);
			});

		// Selects
		$form.find('select').each((index, select) => {
			const $field = $(select);

			$field.on('focus', () => {
				$field.addClass('active');
				$field.parent().siblings('label').addClass('blurred');
			});

			$field.on('change focusout', async () => {
				$field.removeClass('active');
				await _validateField($field);
				await _validateForm($form);
			});
		});

		// Fieldset Inputs (checkbox and radio)
		$form.find('fieldset').each((index, fieldset) => {
			const $inputFields = $(fieldset).find('input');

			$inputFields.change(async () => {
				await _validateFieldset($(fieldset));
				await _validateForm($form);
			});
		});
	};

	const _getFormData = ($form) => {
		const unindexedArray = $form.serializeArray();
		let indexedArray = {};

		if (window.BRAND && BRAND.getDefaultFormData) {
			indexedArray = BRAND.getDefaultFormData(indexedArray);
		}

		$.map(unindexedArray, (n) => {
			const valueArray = [];

			// Store in array if multiple values
			if (indexedArray[n.name]) {
				if (Array.isArray(indexedArray[n.name])) {
					indexedArray[n.name].push(n.value);
				} else {
					valueArray.push(indexedArray[n.name]);
					valueArray.push(n.value);
					indexedArray[n.name] = valueArray;
				}
			} else {
				// convert all "true" and "false" strings to Booleans
				if (n.value === 'true') {
					n.value = true;
				} else if (n.value === 'false') {
					n.value = false;
				}
				indexedArray[n.name] = n.value;
			}
		});

		return indexedArray;
	};

	const _loadGoogleMapsApi = () => {
		// Dont load script if no google key is set in brand config
		if (!CONFIG.googleMapsKey) {
			/* eslint-disable-next-line no-console */
			console.log('No map key available.');
			return false;
		}

		// Create the script tag, set the appropriate attributes
		const script = document.createElement('script');
		script.src = `https://maps.googleapis.com/maps/api/js?key=${CONFIG.googleMapsKey}&callback=initMap`;
		script.async = true;

		// Attach your callback function to the `window` object
		window.initMap = () => {
			isGoogleMapsLoaded = true;

			/* eslint-disable-next-line no-console */
			console.log('Google maps init.');
		};

		document.head.appendChild(script);

		return true;
	};

	const _getCityState = async (requestObject) => {
		const geocoder = new google.maps.Geocoder();
		const zipCode = requestObject.postal_code;
		const addressComponents = {};

		await geocoder.geocode({ address: `${zipCode}` }, (results, status) => {
			if (status === 'OK') {
				const addressArray = results[0].formatted_address.split(', ');

				const [city, stateAndZip] = addressArray;
				const stateAndZipArray = stateAndZip.split(' ');
				const [state] = stateAndZipArray;

				addressComponents.city = city;
				addressComponents.state = state;
			}
		});

		return addressComponents;
	};

	const _bindZipcode = ($zipCode, $city, $state) => {
		const zipRegex = /^\d{5}$/;
		const zipCodeValue = $zipCode.val();

		const requestObject = {
			postal_code: zipCodeValue,
			key: CONFIG.googleMapsKey,
			country: 'US',
		};

		// Dont bind if no google key is set in brand config
		if (!CONFIG.googleMapsKey || !isGoogleMapsLoaded) {
			return false;
		}

		if (zipRegex.test(zipCodeValue)) {
			_getCityState(requestObject).then((response) => {
				if (response && response.city) {
					$city.val(response.city).trigger('focus').trigger('blur');
				} else {
					$city.val('').trigger('focus').trigger('blur');
				}

				if (response && response.state) {
					$state
						.val(response.state)
						.trigger('focus')
						.trigger('change')
						.trigger('blur');
				} else {
					$state
						.find(`option[value='']`)
						.attr('selected', 'selected')
						.trigger('change')
						.trigger('blur');
				}
			});
		}

		return true;
	};

	const _submitForm = async ($form) => {
		const data = _getFormData($form);
		const qString = $form.serialize();
		const formMethod = $form.attr('method');
		const formAction = $form.attr('action');
		const url = `${formAction}${formMethod === 'get' ? `?${qString}` : ''}`;

		const options =
			formMethod === 'post'
				? {
						method: formMethod,
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify(data),
				  }
				: {
						method: formMethod,
						headers: {
							'Content-Type': 'application/json',
						},
				  };

		const request = await fetch(url, options)
			.then((response) => response.json())
			.then((response) => {
				return response;
			})
			.catch((error) => {
				/* eslint-disable-next-line no-console */
				console.log('Request failed: ', error);
				return true;
			});

		/* eslint-disable-next-line no-console */
		console.log('Form Submit', data);

		if ($form.data('scrollTop') !== false) {
			$('html,body').animate({ scrollTop: 0 }, 'slow');
		}

		return request;
	};

	_constructor();

	// output/public
	return {
		bindIncrementer: _bindIncrementer,
		bindFieldEvents: _bindFieldEvents,
		validateField: _validateField,
		validateFieldset: _validateFieldset,
		loadFormJSON: _loadFormJSON,
		getFormData: _getFormData,
		getCityState: _getCityState,
		bindZipcode: _bindZipcode,
		submitForm: _submitForm,
		loadGoogleMapsApi: _loadGoogleMapsApi,
	};
})();
