/* Commerce Utilities for connecting to Magento GraphQL & Rest Services */
/* eslint-disable-next-line no-unused-vars */
const Commerce = (() => {
	let storeID;
	let lastItemAdded = '';
	let lastActionTaken = '';
	let cartMessage = '';
	let baseURL =
		'http://localhost:8080/https://www-ca-uat1-canada-zonnic.non-prod.marketing.bat.net/graphql/';

	const cartUpdate = new CustomEvent('cart-update');
	const cartAction = new CustomEvent('cart-action');
	const loginAction = new CustomEvent('login-action');

	// private method(s)
	const _constructor = () => {
		// replace {PR} in authored graphql endpoint and store value with
		// province code from cookie on each page load
		// let province = Utils.getCookie('regionCode');
		// if (province) {
		// 	const re = /{PR}/gi;
		// 	// Set authored graphql endpoint
		// 	if (typeof graphql !== 'undefined' && graphql !== '') {
		// 		graphql = graphql.replace(re, province);
		// 		baseURL = graphql;
		// 	}
		// 	// Set authored store value
		// 	if (typeof store !== 'undefined' && store !== '') {
		// 		store = store.replace(re, province);
		// 	}
		// }

		/* eslint-disable-next-line no-use-before-define */
		// _cartCookieCheck();

		// window.addEventListener('commerce-interfaces-loaded', () => {
		// 	// only run graphql if regionCode is defined
		// 	province = Utils.getCookie('regionCode');
		// 	if (province) {
		// 		/* eslint-disable-next-line no-use-before-define */
		// 		_checkLogin();
		// 		/* eslint-disable-next-line no-use-before-define */
		// 		_getTypeTable();
		// 	}
		// });
	};

	// Check for cart cookie
	const _cartCookieCheck = () => {
		const cartCookie = document.cookie.match(
			`(^|;) ?commerce-cart-id=([^;]*)(;|$)`
		);
		const cartData = localStorage.getItem('commerce-cart-storage');

		// Clear local cart storage if no cart cookie is found.
		if (!cartCookie && cartData) {
			localStorage.removeItem('commerce-cart-storage');
		}
	};

	// Set Session Storage
	const _setSessionStorage = (id, value) => {
		// Put the object into storage
		sessionStorage.setItem(id, JSON.stringify(value));

		return true;
	};

	// Get Session Storage
	const _getSessionStorage = (id) => {
		const storageObject = sessionStorage.getItem(id);

		// Retrieve the object from storage
		return storageObject;
	};

	// Fetch GET Request
	const _makeGetRequest = async (query, variables) => {
		let returnedVar = false;
		// Clean strings of new lines and unnecessary space
		const queryString = query.replace(/\s\s+/g, ' ');
		const variablesString = variables
			? variables.replace(/\s\s+/g, ' ')
			: '';

		const queryEncodeString = `?query=${encodeURIComponent(queryString)}`;
		const variablesEncodeString = `&variables=${encodeURIComponent(
			variablesString
		)}`;
		const url =
			baseURL +
			queryEncodeString +
			(variables ? variablesEncodeString : '');

		// only do graphql call if regionCode province is set
		const province = Utils.getCookie('regionCode');
		if (province) {
			const request = await fetch(url, {
				method: 'GET',
				headers: requestHeader,
			})
				.then((response) => response.json())
				.then((response) => {
					// Console error messages and return
					if (response.errors && response.errors.length) {
						response.errors.map((error) => {
							/* eslint-disable-next-line no-console */
							console.log('Response failed: ', error.message);
							return false;
						});
					}

					return response;
				})
				.catch((error) => {
					/* eslint-disable-next-line no-console */
					console.log('Request failed: ', error);
				});

			returnedVar = request;
		}
		return returnedVar;
	};

	// Fetch POST Request
	const _makePostRequest = async (query) => {
		const authToken = Utils.getCookie('commerce-auth-token');

		if (authToken) {
			requestHeader.Authorization = `Bearer ${authToken}`;
		} else {
			delete requestHeader.Authorization;
		}

		const request = await fetch(baseURL, {
			method: 'POST',
			headers: requestHeader,
			body: query,
		})
			.then((response) => response.json())
			.then((response) => {
				// Console error messages and return
				if (response.errors && response.errors.length) {
					response.errors.map((error) => {
						/* eslint-disable-next-line no-console */
						console.error(
							'[ERR] Magento Response: ',
							error.message
						);
						return false;
					});
				}

				return response;
			})
			.catch((error) => {
				console.error('[ERR] Magento Response: ', error); // eslint-disable-line no-console
			});

		return request;
	};

	/*
	Product aggregations are groupings by which products can be
	sorted or filtered (e.g. product strength) */
	const _getProductAggregations = async (categoryId) => {
		const requestBody = `{
			products(filter: { category_id: { eq: "${categoryId}" } }, currentPage: 1, pageSize: 1) {
			  aggregations {
				attribute_code
				label
				count
				options {
				  count
				  label
				  value
				}
			  }
			  total_count
			  page_info {
				total_pages
			  }
			}
		  }`;

		const request = await _makeGetRequest(requestBody).then((response) => {
			return response;
		});

		return request;
	};

	const _getTypeTable = async () => {
		/*
		'Type' refers to the {type} field in Magento which stores 'Nano', 'Core'
		It does NOT refer to the {__typename} field which stores 'SimpleProduct','ConfigurableProduct		
		The lookup table is needed to translate the type integers (6924, 6925) -- returned from a
		Products query -- to descriptions (Nano, Core) */

		const typeTable = _getSessionStorage('type-table');
		const typeTableLoaded = new Event('type-table-loaded');

		if (typeTable && typeTable !== 'undefined') {
			window.dispatchEvent(typeTableLoaded);
		} else {
			const requestBody = `{
				customAttributeMetadata(
					attributes: [{ attribute_code: "type", entity_type: "catalog_product" }]
				) {
					items {
						attribute_code
						attribute_options {
							value
							label
						}
						entity_type
					}
				}
			}`;

			await _makeGetRequest(requestBody).then((response) => {
				if (response) {
					const attributeOptions =
						response.data.customAttributeMetadata.items[0]
							.attribute_options;
					_setSessionStorage('type-table', attributeOptions);
					window.dispatchEvent(typeTableLoaded);
				}
				return response;
			});
		}
	};

	const _getCategoryList = async (categoryId, customAttributes) => {
		const requestBody = `{
			${
				customAttributes
					? `
			customAttributeMetadata(
				attributes: [${customAttributes}]
			) {
				items {
					attribute_code
					attribute_type
					entity_type
					input_type
					attribute_options {
					value
					label
					}
				}
			},`
					: ``
			}
			categoryList (
				filters: {
					category_uid: { eq: "${categoryId}" }
				}
			) {
				${categoryListInterface}
			}
		}`;

		const request = await _makeGetRequest(requestBody).then((response) => {
			return response;
		});

		return request;
	};

	// Get Product Catalog
	/* eslint-disable-next-line no-unused-vars */
	const _getProductCatalog = async (
		categoryIds,
		filterProps,
		productSKU,
		currentPage,
		pageSize,
		sortParam,
		priceRange,
		searchString
	) => {
		createCategoriesString = (categories) => {
			let string = '';
			if (categories) {
				categories.forEach((category) => {
					string += `"${category}",`;
				});
				return `
					category_id: { 
						in: [${string}] 
					}
				`;
			}
			return string;
		};

		createPropertiesString = (properties) => {
			let string = '';
			if (properties) {
				const propertiesObject =
					!!properties &&
					properties.reduce((acc, cur) => {
						/* eslint-disable-next-line no-return-assign */
						return !acc[cur.filterProp]
							? {
									...acc,
									[cur.filterProp]: `"${cur.filterValue}",`,
							  }
							: {
									...acc,
									[cur.filterProp]: (acc[
										cur.filterProp
									] += `"${cur.filterValue}",`),
							  };
					}, {});
				Object.keys(propertiesObject).forEach((key) => {
					string += `${key}: { in: [${propertiesObject[key]}] }`;
				});
			}
			return string;
		};

		createSKUstring = (sku) => {
			let string = '';
			if (sku) {
				string = `sku: {eq: "${sku}"}`;
			}
			return string;
		};

		createPriceRangeString = () => {
			let string = '';
			if (priceRange) {
				string = `
					price: {
						from: "${priceRange.from}"
						to: "${priceRange.to}"
					}
				`;
			}
			return string;
		};

		createSearchString = () => {
			let string = '';
			if (searchString) {
				string = `search: "${searchString}"`;
			}
			return string;
		};

		// ---------- START: Assign requestBody

		let requestBody;

		// The variable 'sortParam' holds data for a custom sort-order of products
		// 'sortParam' is sent via graphQL request, forcing Magento to return products in a particular order
		// For PLP onLoad, product sort should remain as-is from Magento admin (not forcing a custom sort)
		// To do so, 'sortParam' is set to undefined on PLP page-load
		// If 'sortParam' is undefined, do not include 'sort' property in requestBody to graphQL
		// When a user applies a sort order, 'sortParam' is defined and the 'sort' property is included in requestBody

		if (!sortParam) {
			requestBody = `{
				products(
					filter: { 
						${createCategoriesString(categoryIds)}
						${createPropertiesString(filterProps)}
						${createSKUstring(productSKU)}
						${createPriceRangeString()}
					}
					${createSearchString()}
					currentPage: ${currentPage}
					pageSize: ${pageSize}
				) {
					items 	{
						${productPropertiesInterface}
						${customProductPropertiesInterface}
						${configurableInterface}
						${bundleInterface}
					}
					total_count
					page_info {
						current_page
						page_size
						total_pages
					}
				}
			}`;
		} else if (sortParam) {
			requestBody = `{
				products(
					filter: { 
						${createCategoriesString(categoryIds)}
						${createPropertiesString(filterProps)}
						${createSKUstring(productSKU)}
						${createPriceRangeString()}
					}
					${createSearchString()}
					sort: {
						${(!!sortParam && sortParam.sortProp) || 'position'}: ${
				(!!sortParam && sortParam.sortDir) || 'DESC'
			}
					}
					currentPage: ${currentPage}
					pageSize: ${pageSize}
				) {
					items 	{
						${productPropertiesInterface}
						${customProductPropertiesInterface}
						${configurableInterface}
						${bundleInterface}
					}
					total_count
					page_info {
						current_page
						page_size
						total_pages
					}
				}
			}`;
		}
		// ---------- END: Assign requestBody

		const request = await _makeGetRequest(requestBody).then((response) => {
			return response;
		});

		return request;
	};

	// Get Product Category List with uid
	/* eslint-disable-next-line no-unused-vars */
	const _getProductCategoryList = async (
		categoryIds,
		filterProps,
		productSKU,
		currentPage,
		pageSize,
		sortParam,
		priceRange,
		searchString
	) => {
		createCategoriesString = (categories) => {
			let string = '';

			if (categories) {
				categories.forEach((category) => {
					string += `"${category}",`;
				});
				return `
					category_uid: { 
						eq: ${string} 
					}
				`;
			}
			return string;
		};

		createPropertiesString = (properties) => {
			let string = '';
			if (properties) {
				const propertiesObject =
					!!properties &&
					properties.reduce((acc, cur) => {
						/* eslint-disable-next-line no-return-assign */
						return !acc[cur.filterProp]
							? {
									...acc,
									[cur.filterProp]: `"${cur.filterValue}",`,
							  }
							: {
									...acc,
									[cur.filterProp]: (acc[
										cur.filterProp
									] += `"${cur.filterValue}",`),
							  };
					}, {});
				Object.keys(propertiesObject).forEach((key) => {
					string += `${key}: { in: [${propertiesObject[key]}] }`;
				});
			}
			return string;
		};

		createSKUstring = (sku) => {
			let string = '';
			if (sku) {
				string = `sku: {eq: "${sku}"}`;
			}
			return string;
		};

		createPriceRangeString = () => {
			let string = '';
			if (priceRange) {
				string = `
					price: {
						from: "${priceRange.from}"
						to: "${priceRange.to}"
					}
				`;
			}
			return string;
		};

		createSearchString = () => {
			let string = '';
			if (searchString) {
				string = `search: "${searchString}"`;
			}
			return string;
		};

		const requestBody = `{
			categoryList(
			  filters: {
				${createCategoriesString(categoryIds)}
				${createPropertiesString(filterProps)}
				${createSKUstring(productSKU)}
				${createPriceRangeString()}
			  }
			)
			{
			  products(
				${createSearchString()}
				sort: {
					${(!!sortParam && sortParam.sortProp) || 'position'}: ${
			(!!sortParam && sortParam.sortDir) || 'DESC'
		}
				}
				currentPage: ${currentPage}
				pageSize: ${pageSize}
			  ) {
				items {
					${productPropertiesInterface}
					${customProductPropertiesInterface}
					${configurableInterface}
					${bundleInterface}
				}
				total_count
				page_info {
				  current_page
				  page_size
				  total_pages
				}
			  }
			}
		  }`;

		const request = await _makeGetRequest(requestBody).then((response) => {
			return response;
		});

		return request;
	};

	// Get One Product
	const _getProduct = async (productID) => {
		// Catch no productId.
		if (!productID) {
			return Promise.resolve('Need Product Id.');
		}

		const requestBody = `{
            products(
                filter: { sku: {eq: "${productID}"} }
            ) {
                items {
					${productPropertiesInterface}
					${customProductPropertiesInterface}
					${configurableInterface}
					${bundleInterface}
					${relatedProductsInterface}
                }
            }
		}`;

		const request = await _makeGetRequest(requestBody);
		return request;
	};

	/* Add Simple Product to Cart */
	const _addSimpleProduct = async (itemToAdd) => {
		// Catch no product
		if (!itemToAdd) {
			return Promise.resolve(
				'[ERR] addSimpleProduct(): No product provided'
			);
		}

		const requestBody = `
		mutation addSimpleProductToCart(
			$cartId: String!
			$sku: String!
			$quantity: Float!
		) {
			addSimpleProductsToCart(
				input: {
					cart_id: $cartId
					cart_items: [
						{ 
							data: { 
								quantity: $quantity, 
								sku: $sku 
							}
						}
					]
				}
			) {
				cart {
					items {
						id
						product {
							id
							name
							sku
						}
						${
							itemToAdd.productDelivery
								? `subscription_data {
								is_subscription_item
								max_qty
								min_qty
								options {
								  label
								  value
								}
							}`
								: ''
						}
						quantity
					}
				}
			}
		}`;

		const variablesObj = {
			cartId: itemToAdd.cartId,
			sku: itemToAdd.productSku,
			quantity: itemToAdd.productQuantity,
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);
		return request;
	};

	/* Add Configurable Product to Cart */
	const _addConfigurableProduct = async (cartObject) => {
		// Catch no productId.
		if (!cartObject) {
			return Promise.resolve('Need Cart Object.');
		}

		const requestBody = `mutation addConfigurableProductToCart(
			$cartId: String!
			$parentSku: String!
			$sku: String!
			$quantity: Float!
		) {
			addConfigurableProductsToCart(
				input: {
					cart_id: $cartId
					cart_items: [
						{
							parent_sku: $parentSku,
							data: { 
								quantity: $quantity, 
								sku: $sku 
							}
						}
					]
				}
			) {
				cart {
					items {
						id
						quantity
						product {
							id
							name
							sku
						}
						${
							cartObject.productDelivery
								? `subscription_data {
								is_subscription_item
								max_qty
								min_qty
								options {
								  label
								  value
								}
							}`
								: ''
						}
						... on ConfigurableCartItem {
							configurable_options {
								option_label
							}
						}
					}
				}
			}
		}`;

		const variablesObj = {
			cartId: cartObject.cartId,
			parentSku: cartObject.familySku,
			sku: cartObject.productSku,
			quantity: cartObject.productQuantity,
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);
		return request;
	};

	/* Add Bundle Product to Cart */
	const _addBundleProduct = async (cartObject) => {
		// Catch no productId.
		if (!cartObject) {
			return Promise.resolve('Need Cart Object.');
		}

		const requestBody = `mutation addBundleProductToCart(
			$cartId: String!
			$sku: String!
			$quantity: Float!
			$bundleOptions: [BundleOptionInput]!
		) {
			addBundleProductsToCart(
				input: {
					cart_id: $cartId
					cart_items: [
						{
							data: { 
								sku: $sku, 
								quantity: $quantity 
							}
							bundle_options: $bundleOptions
						}
					]
				}
			) {
				cart {
					items {
						id
						quantity
						product {
							sku
						}
						... on BundleCartItem {
							bundle_options {
								id
								label
								type
								values {
									id
									label
									price
									quantity
								}
							}
						}
					}
				}
			}
		}`;

		const variablesObj = {
			cartId: cartObject.cartId,
			sku: cartObject.productSku,
			quantity: cartObject.productQuantity,
			bundleOptions: cartObject.bundleOptions,
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);
		return request;
	};

	/* Product Price Formatter */
	const _priceFormatter = (priceValue, priceType) => {
		let price = '';

		switch (priceType) {
			case 'CAD':
				price = `$${priceValue}`;
				break;
			case 'EUR':
				price = `${priceValue} &#8364;`;
				break;
			case 'GBP':
				price = `&#163;${priceValue}`;
				break;
			case 'PLN':
				price = `${priceValue} &#122;&#322;`;
				break;
			case 'USD':
				price = `${priceValue}`;
				break;
			case 'ZAR':
				price = `R ${priceValue}`;
				break;
			default:
				price = `${priceValue}`;
		}
		return price;
	};

	/* Get Cart */
	const _getCart = async (cartId) => {
		// Catch no cartId
		if (!cartId) {
			return Promise.resolve('[ERR] getCart(): No cart id provided');
		}

		const requestBody = `query getCartDetails($cartId: String!) {
			cart(cart_id: $cartId) {
				email
				billing_address {
					city
					country {
						code
						label
					}
					firstname
					lastname
					postcode
					region {
						code
						label
					}
					street
					telephone
				}
				items {
					id
					product {
						${customProductPropertiesInterface}
						${productPropertiesInterface}
						... on ConfigurableProduct {
							variants {
								product {
									id
									sku
									${customProductPropertiesInterface}
									small_image {
										label
										url
									}
								}
							}
						}
					}
					prices {
						row_total {
						  value
						  currency
						}
					}
					... on ConfigurableCartItem {
						configurable_options {
							id
							option_label
							value_id
							value_label
						}
					}
					... on BundleCartItem {
						bundle_options {
							id
							label
							type
							values {
								id
								label
								price
								quantity
							}
						}
					}
					quantity
				}
				available_payment_methods {
					code
					title
				}
				selected_payment_method {
					code
					title
				}
				applied_coupons {
					code
				}
				prices {
					grand_total {
						value
						currency
					}
					subtotal_excluding_tax {
						value
						currency
					}
				}
			}
		}`;

		const variablesObj = {
			cartId,
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query).then((response) => {
			// Add info on user action for messagebar
			response.lastItemAdded = lastItemAdded;
			response.lastActionTaken = lastActionTaken;
			response.cartMessage = cartMessage;

			// Remove null cart items
			response.data.cart.items = response.data.cart.items.filter(
				(x) => x !== null
			);

			Utils.setLocalStorage('commerce-cart-storage', response);

			// Trigger cart update window event
			window.dispatchEvent(cartUpdate);

			return response;
		});

		return request;
	};

	/* Set Cart Cookie */
	const _setCommerceCookie = (key, value, min) => {
		if (typeof store !== 'undefined') {
			const pathArray = store.split('_');
			storeID = `/${pathArray[1]}/${pathArray[3]}`;
		}

		const expires = new Date();
		expires.setTime(expires.getTime() + min * 60 * 1000);
		const isSecure = window.location.protocol === 'https:';
		const domain = document.domain.includes('.com')
			? `domain=.${document.domain.match(/[^.]*\.[^.]*$/)[0]};`
			: '';
		const cookieString = `${key}=${value};${
			isSecure ? 'secure;' : ''
		}samesite=strict;expires=${expires.toUTCString()};path=${storeID};${domain}`;
		document.cookie = cookieString;
	};

	/* Erase Cart Cookie */
	const _eraseCommerceCookie = (key) => {
		if (typeof store !== 'undefined') {
			const pathArray = store.split('_');
			storeID = `/${pathArray[1]}/${pathArray[3]}`;
		}
		const isSecure = window.location.protocol === 'https:';
		const domain = document.domain.includes('.com')
			? `domain=.${document.domain.match(/[^.]*\.[^.]*$/)[0]};`
			: '';
		const cookieString = `${key}=;${
			isSecure ? 'secure;' : ''
		}samesite=strict;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=${storeID};${domain}`;
		document.cookie = cookieString;
	};

	/* Create Empty Cart */
	const _createEmptyCart = async () => {
		const authToken = Utils.getCookie('commerce-auth-token');
		const requestBody = authToken
			? '{customerCart{id}}'
			: 'mutation {createEmptyCart}';

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query).then((response) => {
			const cartId = authToken
				? response.data.customerCart.id
				: response.data.createEmptyCart;

			_setCommerceCookie('commerce-cart-id', cartId, 20);
			_getCart(cartId);
			window.dispatchEvent(loginAction);
			return cartId;
		});

		return request;
	};

	/* Set Auth Token */
	const _setAuthToken = async (email, password) => {
		const requestBody = `mutation {
			generateCustomerToken(email: "${email}", password: "${password}") {
			  token
			}
		  }`;
		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query).then((response) => {
			const { errors } = response;

			if (!errors) {
				const authToken = response.data.generateCustomerToken.token;
				Utils.setCookie('commerce-auth-token', authToken, 60);
				return { authToken };
			}
			return { errors };
		});

		return request;
	};

	/* Merge Carts */
	const _mergeCarts = async (currentCartId) => {
		const requestBody = `mutation {
			mergeCarts(
			  source_cart_id: "${currentCartId}"
			) {
			  items {
				id
				product {
				  name
				  sku
				}
				quantity
			  }
			}
		}`;

		const query = JSON.stringify({ query: requestBody });

		_makePostRequest(query).then((response) => {
			return response;
		});
	};

	/* Check Login */
	const _checkLogin = async () => {
		const isLocalhost = location.hostname === 'localhost';

		const requestBody = `{
			me {
				is_logged_in
				active_cart_id
				#customer_id
			}
	  	}`;

		const query = JSON.stringify({ query: requestBody });

		const request = await _makePostRequest(query).then((response) => {
			if (response) {
				const {
					active_cart_id: responseCartId,
					is_logged_in: isLoggedIn,
					customer_id: customerId = null,
				} = response.data.me;

				Utils.setCookie('commerce-auth-verify', isLoggedIn);

				if (responseCartId) {
					_setCommerceCookie('commerce-cart-id', responseCartId, 20);

					// responseCartId returns as null on localhost for guest carts. do not remove commerce-cart-id on localhost
				} else if (!isLocalhost) {
					_eraseCommerceCookie('commerce-cart-id');
					localStorage.removeItem('commerce-cart-storage');
				}

				if (customerId) {
					Utils.setLocalStorage('customer-id', customerId);
				} else {
					Utils.removeLocalStorage('customer-id');
				}

				window.dispatchEvent(loginAction); // ---| Evt Listeners, 'login-action'
			}
			return response;
		});

		return request;
	};

	/* Login Customer */
	const _loginCustomer = async () => {
		const sourceCartId = Utils.getCookie('commerce-cart-id');
		const authToken = Utils.getCookie('commerce-auth-token');

		if (authToken && sourceCartId) {
			_mergeCarts(sourceCartId).then((response) => {
				return `${authToken} ${response}`;
			});
		}

		return authToken || 'no magento authToken found';
	};

	/* Logout Customer */
	const _logoutCustomer = () => {
		const authToken = Utils.getCookie('commerce-auth-token');

		if (authToken) {
			_eraseCommerceCookie('commerce-cart-id');
			_eraseCommerceCookie('commerce-auth-token');
			Utils.removeLocalStorage('commerce-cart-storage');
		}

		window.dispatchEvent(cartUpdate);
	};

	/* Remove Item From Cart */
	const _removeItemFromCart = async (cartItemId) => {
		const cartId = Utils.getCookie('commerce-cart-id');

		// Trigger cart update window event
		window.dispatchEvent(cartAction);

		// If no cartId or no cartItemId.
		if (!cartId || !cartItemId) {
			return Promise.resolve('Need cartId and cartItemId.');
		}

		const requestBody = `mutation removeItemFromCart($cartId: String!, $cartItemId: Int!) {
			removeItemFromCart(input: { cart_id: $cartId, cart_item_id: $cartItemId }) {
				cart {
					items {
						id
						product {
							name
						}
						quantity
					}
					prices {
						grand_total {
							value
							currency
						}
					}
				}
			}
		}`;

		const variablesObj = {
			cartId,
			cartItemId,
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);

		/* eslint-disable-next-line no-console */
		console.log('removeItemFromCart', request);

		// Add cart message if in response
		cartMessage = request.message ? request.message : '';

		// Update local storage
		lastActionTaken = 'remove';
		_getCart(cartId);

		return request;
	};

	/* Update Item From Cart */
	const _updateItemInCart = async (cartItemId, quantity) => {
		const cartId = Utils.getCookie('commerce-cart-id');

		// Trigger cart update window event
		window.dispatchEvent(cartAction);

		// If no cartId, cartItemId and cartItemQuantity.
		if (!cartId || !cartItemId || !quantity) {
			return Promise.resolve('Need cartId, cartItemId and quantity.');
		}

		const requestBody = `mutation updateCartItem(
			$cartId: String!
			$cartItemId: Int!
			$quantity: Float!
		) {
			updateCartItems(
				input: {
					cart_id: $cartId
					cart_items: [{ cart_item_id: $cartItemId, quantity: $quantity }]
				}
			) {
				cart {
					items {
						id
						product {
							name
						}
						quantity
					}
					prices {
						grand_total {
							value
							currency
						}
					}
				}
			}
		}`;

		const variablesObj = {
			cartId,
			cartItemId,
			quantity,
		};

		const query = JSON.stringify({
			query: requestBody,
			variables: variablesObj,
		});

		const request = await _makePostRequest(query);

		// Add cart message if in response
		cartMessage = request.message
			? request.message
			: request.errors
			? request.errors[0].message
			: '';

		// Update local storage
		lastActionTaken = 'update';
		_getCart(cartId);

		return request;
	};

	/* Add Item To Cart Analytics */
	const _dispatchAddItemToCartEvent = (productObject) => {
		const eventData = {
			detail: {
				event: 'add_to_cart',
				ecommerce: {
					items: [
						{
							item_name: productObject.productName,
							item_id: productObject.productSku,
							price: productObject.finalPrice,
							item_brand: 'zonnic',
							item_category: productObject.categories[0].name,
							item_category_2: productObject.categories[1].name,
							item_variant: productObject.productTypeAttrib,
							item_list_name: productObject.productName,
							item_list_id: productObject.productUID,
							quantity: productObject.productQuantity,
						},
					],
				},
			},
		};
		const addItemToCartEvent = new CustomEvent('addItemToCart', eventData);
		document.dispatchEvent(addItemToCartEvent);
	};

	/* Add Item To Cart */
	const _addItemToCart = async (product) => {
		let cartId = Utils.getCookie('commerce-cart-id');
		let request = '';

		/* TRIGGER CART UPDATE EVENT
		Search code repo for 'cart-action' to find event listeners */
		window.dispatchEvent(cartAction);

		// Get product name to show in message bar
		lastActionTaken = 'add';
		lastItemAdded = product.productName;
		// Utils.setCookie('commerce-item-message', product.productName);

		// If no cartId, create one.
		if (!cartId) {
			cartId = await _createEmptyCart();
		}
		product.cartId = cartId;

		switch (product.productType) {
			case 'SimpleProduct':
				request = await _addSimpleProduct(product);
				break;
			case 'ConfigurableProduct':
				request = await _addConfigurableProduct(product);
				break;
			case 'BundleProduct':
				request = await _addBundleProduct(product);
				break;
			default:
		}

		// Add cart message or error message if in response
		/* eslint-disable-next-line no-nested-ternary */
		cartMessage = request.message
			? request.message
			: request.errors
			? request.errors[0].message
			: '';

		const cart = await _getCart(cartId);

		_dispatchAddItemToCartEvent(product);

		return cart;
	};

	/* Get Cart Total Quantity */
	const _getCartQuantity = () => {
		const localCart = $.parseJSON(
			Utils.getLocalStorage('commerce-cart-storage')
		);

		let cartQuantity = 0;

		if (
			localCart &&
			localCart.data &&
			localCart.data.cart &&
			localCart.data.cart.items.length > 0
		) {
			cartQuantity = localCart.data.cart.items
				.map((item) => {
					return item.quantity;
				})
				.reduce((prev, next) => {
					return prev + next;
				});
		}

		return cartQuantity;
	};

	const _getCreditCardType = (cc, shortFormat) => {
		const amex = new RegExp('^3[47][0-9]{13,14}$');
		const visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');

		const mastercard = new RegExp('^5[1-5][0-9]{14}$');
		const mastercard2 = new RegExp('^2[2-7][0-9]{14}$');

		const disco1 = new RegExp('^6011[0-9]{12}[0-9]*$');
		const disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$');
		const disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$');

		if (visa.test(cc)) {
			return shortFormat ? 'VI' : 'VISA';
		}

		if (amex.test(cc)) {
			return shortFormat ? 'AE' : 'AMEX';
		}

		if (mastercard.test(cc) || mastercard2.test(cc)) {
			return shortFormat ? 'MC' : 'MASTERCARD';
		}

		if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
			return shortFormat ? 'DI' : 'DISCOVER';
		}

		return '';
	};

	_constructor();

	// output/public
	return {
		addItemToCart: _addItemToCart,
		checkLogin: _checkLogin,
		createEmptyCart: _createEmptyCart,
		getCart: _getCart,
		getCartQuantity: _getCartQuantity,
		getCategoryList: _getCategoryList,
		getCreditCardType: _getCreditCardType,
		getProduct: _getProduct,
		getProductAggregations: _getProductAggregations,
		getProductCatalog: _getProductCatalog,
		getProductCategoryList: _getProductCategoryList,
		getSessionStorage: _getSessionStorage,
		getTypeTable: _getTypeTable,
		loginCustomer: _loginCustomer,
		logoutCustomer: _logoutCustomer,
		makeGetRequest: _makeGetRequest,
		makePostRequest: _makePostRequest,
		mergeCarts: _mergeCarts,
		priceFormatter: _priceFormatter,
		removeItemFromCart: _removeItemFromCart,
		setAuthToken: _setAuthToken,
		setSessionStorage: _setSessionStorage,
		updateItemInCart: _updateItemInCart,
		registrationForm: () => {}, // placeholder to avoid error
	};
})();
