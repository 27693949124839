/* Commerce Utilities for connecting to Magento GraphQL & Rest Services */
/* eslint-disable-next-line no-unused-vars */
const Account = (() => {
	// private method(s)
	const _constructor = () => {};

	/* Request Password Reset Email, step 1 of 2 */
	/* This is being ran twice currently, first time is success second time emailValue is undefined */
	const _requesetPasswordReset = async (emailValue) => {
		// console.log('what is my email? ', emailValue);

		const requestBody = `mutation myRequestPWReset {
			requestPasswordResetEmail(
				email: "${emailValue}"
			)
		}`;

		// console.log('what is my requestBody? ', requestBody);

		const query = JSON.stringify({ query: requestBody });
		const request = await _makePostRequest(query).then((response) => {
			const { errors } = response;

			if (!errors) {
				// console.log('We have errors!');
				// const authToken = response.data.generateCustomerToken.token;
				// Utils.setCookie('commerce-auth-token', authToken, 60);
				// return { authToken };
			}
			return { errors };
		});

		return request;
	};

	/* Reset password, step 2 of 2 */
	/* resetPasswordToken is hardcoded within passwordResetZonnic-form.js */
	const _resetPassword = async (
		emailValue,
		resetPasswordToken,
		newPassword
	) => {
		const requestBody = `mutation {
			resetPassword(
			  email: "${emailValue}",
			  resetPasswordToken: "${resetPasswordToken}",
			  newPassword: "${newPassword}"
			)
		  }`;

		const query = JSON.stringify({ query: requestBody });

		// might try makeGetRequest if post not working
		const request = await _makePostRequest(query).then((response) => {
			const { errors } = response;

			if (!errors) {
				const authToken = response.data.generateCustomerToken.token;
				Utils.setCookie('commerce-auth-token', authToken, 60);
				return { authToken };
			}
			return { errors };
		});

		return request;
	};

	/* Log out */
	const _requestLogOut = async () => {
		const requestBody = `mutation {
			revokeCustomerToken {
			  result
			}
		}`;

		const query = JSON.stringify({ query: requestBody });

		const request = await Commerce.makePostRequest(query).then(
			(response) => {
				const { errors } = response;

				if (!errors) {
					const cartUpdate = new CustomEvent('cart-update');
					const authToken = Utils.getCookie('commerce-auth-token');
					// if auth token exists remove, set cookies and delete local storage
					if (authToken) {
						Utils.eraseCookie('commerce-cart-id');
						Utils.eraseCookie('commerce-auth-token');
						Utils.eraseCookie('M2SESSID');
						Utils.setCookie('commerce-auth-verify', false);
						Utils.removeLocalStorage('commerce-cart-storage');
					}
					window.dispatchEvent(cartUpdate);

					// get current URL and redirect user to correct homepage
					const currentUrl = window.location.pathname;
					// If FR
					if (currentUrl.includes('/fr')) {
						document.location.href = '/ca/fr';
					} else {
						// got to english
						document.location.href = '/ca/en';
					}
				}
				return { errors };
			}
		);

		return request;
	};

	_constructor();

	// output/public
	return {
		requesetPasswordReset: _requesetPasswordReset,
		resetPassword: _resetPassword,
		requestLogOut: _requestLogOut,
	};
})();
