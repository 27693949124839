// ------------------ brand (static)
/* eslint-disable no-unused-vars */

const BRAND = (() => {
	// private var(s)

	// function that will replace all instances of {PR} or escaped /%7BPR%7D/ in string with
	// provinceCode from regionCode cookie
	const _replaceStringProvinceCode = (st) => {
		/* eslint-disable-next-line */
		//document.cookie.match('(^|;)\\s*' + 'regionCode' + '\\s*=\\s*([^;]+)')?.pop() || '';
		let url = st;
		const province = Utils.getCookie('regionCode');
		const re = /%7BPR%7D/gi; // escaped version from AEM
		const re2 = /{PR}/gi;
		if (province) {
			url = url.replace(re, province);
			url = url.replace(re2, province);
		}
		return url;
	};

	const _getHtmlLang = () => {
		return document.getElementsByTagName('html')[0].getAttribute('lang');
	};

	const _getUrlCountry = () => {
		const countryIndex = location.pathname.startsWith('/content/')? 3: 1;
		const country = location.pathname.split('/');

		return country[countryIndex < country.length? countryIndex: 0];
	};

	const _getUrlCountryLang = () => {
		const langIndex = location.pathname.startsWith('/content/')? 4: 2;
		const lang = location.pathname.split('/');

		return lang[langIndex < lang.length? langIndex: 0];
	};

	const _getDefaultFormData = () => {
		return {
			siteCountry: _getUrlCountry(),
			siteCountryLang: _getUrlCountryLang(),
			siteHtmlLang: _getHtmlLang(),
		};
	};

	const _getUrlPage = () => {
		const urlStr = location.pathname;

		let page = urlStr.substring(urlStr.lastIndexOf('/') + 1);

		if (page.includes(".")) {
			page = page.substring(0, page.indexOf('.'));
		}

		return page;
	};

	// private method(s)
	const _constructor = () => {
		const lang = _getHtmlLang();

		if (lang) {
			try { // prevent any I18n error from stopping code execution
				I18n.initDictionary('gtr', lang); // setup the i18n dictionary
			}
			catch(err) {
				console.log(err);
			}
		}

		// _bindEvents();
		// console.log('Brand is running');
		const $document = $(document);
		// Replace {PR} in default messageBar text with province code
		$document.on('BATComponentsLoaded', () => {
			// update all links in Header 'top' area
			$('.bat-messagebar--default-message a').each((index, a) => {
				const $a = $(a);
				let href = $a.prop('href');
				if (href) {
					href = _replaceStringProvinceCode(href);
					$a.attr('href', href);
				}
			});
		});

		// If current environment is Styleguide/Prototype environment and no regionCode cookie is set,
		// then set cookie to default AB (Alberta) province
		province = Utils.getCookie('regionCode');
		const { domain } = document;
		if (!province) {
			if (domain.includes('localhost') || domain.includes('styleguide')) {
				Utils.setCookie('regionCode', 'AB');
			}
		}

		const $body = $('body');

		if ($body.hasClass('publishMode') && $body.hasClass('exclusive-offer')) {
			const exclusiveOfferVerify = sessionStorage.getItem("exclusive_offer_verify");

			if (exclusiveOfferVerify === "confirmed") {
				$body.css('display', 'block'); // do in this way instead of show() to avoid document loading issue

				if (sessionStorage.getItem("exclusive_offer_verify_one_off") === "confirmed") {
					sessionStorage.removeItem("exclusive_offer_verify");
					sessionStorage.removeItem("exclusive_offer_verify_one_off");
				}
			}
			else {
				window.location.href = 'registration';
			}
		}

		$document.on('click', '.scroll-down-section .bat-section-content', function(){
			$('body, html').animate({scrollTop: window.innerHeight + 300}, 500)
		});

		$(window).scroll(function() {
			if ($(window).scrollTop() > 70) {
				$('.scroll-down-section .bat-section-content').fadeOut(300);
			} else {
				$('.scroll-down-section .bat-section-content').fadeIn(300);
			}
		})

		$document.on('click', '.where-to-buy a', function(e) {
			e.preventDefault();

			analytics.trackEventOnNextPageLoad({
				event: 'WhereToBuy',
				eventAction: 'WhereToBuy',
				eventLabel: _getUrlPage(),
			});

			window.location.href = $(this).attr('href');
		});

		const utmSource = new URLSearchParams(location.search).get('utm_source') || "";

		if (utmSource !== "") { // not to replace storage value with a currently empty value
			sessionStorage.setItem("utm_source", utmSource);
		}
	};

	// Bind Events
	const _bindEvents = () => {
		// console.log('Bind Events Here');
	};

	_constructor();

	// output/public
	return {
		bindEvents: _bindEvents,
		replaceStringProvinceCode: _replaceStringProvinceCode,
		getHtmlLang: _getHtmlLang,
		getUrlCountry: _getUrlCountry,
		getUrlCountryLang: _getUrlCountryLang,
		getDefaultFormData: _getDefaultFormData,
		getUrlPage: _getUrlPage,
	};
})();
