/* eslint-disable no-unused-vars */
/* eslint-disable default-case */

const checkLogin = async () => {
	const tokenReq = Utils.getCookie('commerce-auth-verify');
	return tokenReq === 'true';
};

const handleLoginTrackingEvent = (profile) => {
	const eventData = {
		detail: {
			visitor: {
				userType: 'authenticated',
				accountNo: profile['ns0:AccountNo'],
				customerId: profile['ns0:PrimeAcctno'],
				city: profile['ns0:Address'][0]['ns0:City'],
				state: profile['ns0:Address'][0]['ns0:StateCode'],
				zip: profile['ns0:Address'][0]['ns0:ZipCode'],
				data: '',
			},
		},
	};

	const logInEvent = new CustomEvent('logIn', eventData);

	document.dispatchEvent(logInEvent);
};

const handleLogin = async (req, component, userName, password) => {
	if (req.error) {
		switch (req.error) {
			case 'USERID': {
				const messageElement = component.querySelector('.USERID');
				messageElement.classList.add('active');
				break;
			}
			case 'PASSWORD': {
				const messageElement = component.querySelector('.PASSWORD');
				messageElement.classList.add('active');
				break;
			}
		}
	}

	if (req.Credentials && req.Credentials.WebAccess !== 'U') {
		switch (req.Credentials.WebAccess) {
			case 'S': {
				const messageElement = component.querySelector('.SOFTLOCK');
				messageElement.classList.add('active');
				break;
			}
			case 'H': {
				window.location.replace(component.data.hardLockUrl);
				break;
			}
			case 'P': {
				window.location.replace(component.data.hardLockUrl);
				break;
			}
		}
	}

	if (
		req.Credentials &&
		req.Profile &&
		req.Credentials.WebAccess === 'U' &&
		req.Profile['ns0:Qualified'] === 'N'
	) {
		window.location.replace(component.data.hardLockUrl);
	}

	if (
		req.Credentials &&
		req.Profile &&
		req.Credentials.WebAccess === 'U' &&
		req.Credentials.LoginReset === 'Y'
	) {
		window.location.replace(component.data.hardLockUrl);
	}

	if (
		req.Credentials &&
		req.Profile &&
		req.Credentials.WebAccess === 'U' &&
		req.Credentials.LoginReset === 'N' &&
		req.Profile['ns0:Qualified'] === 'Y'
	) {
		const messageElement = component.querySelector('.LOGIN');
		messageElement.classList.add('active');
		const credentialsReq = await putAccessCredentials({
			...req.JWT,
			PrimeAcctNo: req.Credentials.PrimeAcctNo,
		});
		const profileReq = await putUserProfile(req.Profile);
		const commerceReq = await Commerce.loginCustomer(userName, password);
		if (credentialsReq && commerceReq && profileReq) {
			const urlParams = new URLSearchParams(window.location.search);
			const redirectUrl = urlParams.get('resource') || '/';
			handleLoginTrackingEvent(req.Profile);
			const { domain } = document;
			const magentoVerifyProfile = `/us/shop/rai_customer/account/verifyProfile/?token=${
				req.JWT.refreshToken
			}&referer=${encodeURIComponent(
				`${window.location.protocol}//${window.location.hostname}${redirectUrl}`
			)}`;

			if (
				!domain.includes('localhost') &&
				!domain.includes('styleguide')
			) {
				window.location.replace(magentoVerifyProfile);
			} else {
				redirectUrl && window.location.replace(redirectUrl);
			}
		}
	}
};
