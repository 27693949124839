// ------------------ I18n (static)
/* eslint-disable-next-line no-unused-vars */
const I18n = (() => {
	// private var(s)
	let dictionary;

	// private method(s)
	const _constructor = () => {};

	const _patchText = (text, snippets) => {
		let i;
		let newText = text;
		if (snippets) {
			if (Array.isArray(snippets)) {
				for (i = 0; i < snippets.length; i++) {
					newText = newText.replace(`{${i}}`, snippets[i]);
				}
			} else {
				newText = newText.replace('{0}', snippets);
			}
		}
		return newText;
	};

	const _regexEscape = (string) => {
		// https://makandracards.com/makandra/15879-javascript-how-to-generate-a-regular-expression-from-a-string
		/* eslint-disable-next-line no-useless-escape */
		return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
	};

	// string replace that replaces all instances, not just first occurance
	const _replaceG = (original, searchTxt, replaceTxt) => {
		// first need to escape special characters for safe use in regex
		const searchTxt2 = _regexEscape(searchTxt);
		const regex = new RegExp(searchTxt2, 'g');
		return original.replace(regex, replaceTxt);
	};

	const _initDictionary = (brand, locale) => {
		const dictionaryUrl = `/content/dam/${brand}/dictionary/${locale}.json`;
		const _makeGetRequest = async () => {
			const request = await fetch(dictionaryUrl, {
				method: 'GET',
			})
				.then((response) => response.json())
				.then((response) => {
					return response;
				})
				.catch((error) => {
					/* eslint-disable-next-line no-console */
					console.log('Request failed: ', error);
				});

			dictionary = request;
			sessionStorage.setItem('dictionary', JSON.stringify(dictionary));
			sessionStorage.setItem('dictionaryBrand', brand);
			sessionStorage.setItem('dictionaryLocale', locale);
			window.location.reload();
			// return dictionary;
		};

		if (sessionStorage.getItem('dictionary') !== null) {
			// A dictionary already exists in sessionStorage.
			// Check that cached dictionary matches this page's brand/locale since
			// other brands/locale may exist on this same domain
			const dictionaryBrand = sessionStorage.getItem('dictionaryBrand');
			const dictionaryLocale = sessionStorage.getItem('dictionaryLocale');
			if (brand === dictionaryBrand && locale === dictionaryLocale) {
				dictionary = JSON.parse(sessionStorage.getItem('dictionary'));
			} else {
				_makeGetRequest(); // request fresh dictionary
			}
		} else {
			_makeGetRequest();
		}
	};

	/**
	 * Translates the specified text into the current language.
	 */
	const _get = (text, snippets) => {
		const dict = dictionary;
		let newText;

		/* eslint-disable-next-line no-nested-ternary */
		const lookupText = text;

		if (dict) {
			newText = dict[lookupText];
		}
		if (!newText) {
			newText = text;
		}
		return _patchText(newText, snippets);
	};

	/**
	 * Translates the specified text into the current language. Use this
	 * method to translate String variables, e.g. data from the server.
	 */
	const _getVar = (text) => {
		if (!text) {
			return null;
		}
		return _get(text, null);
	};

	const _render = (str) => {
		// perform i18n lookup on strings matching <% label %> format
		const regex = /<%([^%>]*)%>/gm;

		/* eslint-disable-next-line func-names */
		return str.replace(regex, function (m, $1) {
			// trim whitespace
			// this lets you write <% nextText %> instead of requiring <%nextText%>
			/* eslint-disable-next-line no-param-reassign */
			$1 = $1.trim();

			// allows parameters to be passed from HBS through i18n
			// ex: <% PAGE_LABEL[5][100] %> = "page 5 of 100"
			const regExParams = /\[.*?\]/gm;
			const params = [];
			let match = regExParams.exec($1);
			while (match !== null) {
				//	console.log(`Found ${match[0]} start=${match.index} end=${regExParams.lastIndex}.`);
				let paramStr = `${match[0]}`;
				paramStr = _replaceG(paramStr, '[', '');
				paramStr = _replaceG(paramStr, ']', '');
				params.push(paramStr);
				match = regExParams.exec($1);
			}

			// strip parameter [] section off field name
			if ($1.indexOf('[') > -1) {
				/* eslint-disable-next-line no-param-reassign */
				$1 = $1.substring(0, $1.indexOf('[')); // get everything left of the first [
			}

			if (params.length) {
				// this i18n request contains params
				return _get($1, params);
			}

			// regular i18n lookup with no special params
			return _getVar($1);
		});
	};

	_constructor();

	// output/public
	return {
		render: _render,
		initDictionary: _initDictionary,
	};
})();
