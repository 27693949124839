/* eslint-disable no-unused-vars */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable prefer-const */

async function removeAccessCredentials() {
	const databaseName = 'BATAccessTokens';
	const databaseStore = 'BATAccessTokens';
	return new Promise((resolve, reject) => {
		const dbrequest = indexedDB.open(databaseName);
		dbrequest.onupgradeneeded = () => {
			const database = dbrequest.result;
			database.createObjectStore(databaseStore, { autoIncrement: true });
			database.onerror = (event) =>
				reject(`ERROR:  ${event.target.errorCode}`);
		};
		dbrequest.onerror = (event) =>
			reject(`ERROR:  ${event.target.errorCode}`);
		dbrequest.onsuccess = (event) => {
			const database = dbrequest.result;
			let transaction;
			let store;
			transaction = database.transaction(databaseStore, 'readwrite');
			store = transaction.objectStore(databaseStore);
			const request = store.delete(1);
			request.onsuccess = () => {
				resolve('Credentials Removed');
			};
			transaction.oncomplete = () => database.close();
			database.onerror = () =>
				reject(`ERROR:  ${event.target.errorCode}`);
		};
	});
}

async function putAccessCredentials(credentials) {
	const databaseName = 'BATAccessTokens';
	const databaseStore = 'BATAccessTokens';
	return new Promise((resolve, reject) => {
		const dbrequest = indexedDB.open(databaseName, 1);
		dbrequest.onupgradeneeded = () => {
			const database = dbrequest.result;
			database.createObjectStore(databaseStore, { autoIncrement: true });
			database.onerror = (event) =>
				reject(`ERROR:  ${event.target.errorCode}`);
		};
		dbrequest.onerror = (event) =>
			reject(`ERROR:  ${event.target.errorCode}`);
		dbrequest.onsuccess = (event) => {
			const database = dbrequest.result;
			let transaction;
			let store;
			transaction = database.transaction(databaseStore, 'readwrite');
			store = transaction.objectStore(databaseStore);
			const request = store.put(credentials, 1);
			request.onsuccess = () => resolve(credentials);
			transaction.oncomplete = () => database.close();
			database.onerror = () =>
				reject(`ERROR:  ${event.target.errorCode}`);
		};
	});
}

async function getAccessCredentials() {
	const databaseName = 'BATAccessTokens';
	const databaseStore = 'BATAccessTokens';
	return new Promise((resolve, reject) => {
		const dbrequest = indexedDB.open(databaseName, 1);
		dbrequest.onupgradeneeded = (event) => {
			const database = dbrequest.result;
			database.createObjectStore(databaseStore, { autoIncrement: true });
			database.onerror = () =>
				reject(`ERROR:  ${event.target.errorCode}`);
		};
		dbrequest.onerror = (event) =>
			reject(`ERROR:  ${event.target.errorCode}`);
		dbrequest.onsuccess = (event) => {
			const database = dbrequest.result;
			let transaction;
			let store;
			transaction = database.transaction(databaseStore, 'readonly');
			store = transaction.objectStore(databaseStore);
			const request = store.get(1);
			request.onsuccess = () => {
				if (request.result) resolve(request.result);
				resolve(null);
			};
			transaction.oncomplete = () => database.close();
			database.onerror = () => resolve(null);
		};
	});
}
