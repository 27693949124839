// ------------------ analytics (static)

/* eslint-disable-next-line no-unused-vars */
const analytics = (() => {
	const eventTracking = [];

	// Track Event
	/* eslint-disable-next-line no-unused-vars */
	const _trackEvent = (eventData) => {
		// Some functions pass in a 'detail' object, others don't. Probably
		// all should be consistent and pass 'detail', but until it can be
		// refactored, we use a conditional to push the correct data
		const data = eventData.detail? eventData.detail: eventData;

		if (typeof dataLayer !== 'undefined') {
			dataLayer.push(data);
		}
		else if (data.eventCallback) {
			data.eventCallback();
		}
	};

	// Bind Events
	const _bindEvents = () => {
		document.addEventListener('eventTracking', (e) => {
			_trackEvent(e);
		});
	};

	const _trackEventOnNextPageLoad = (eventData) => {
		eventTracking.push(eventData);
		sessionStorage.setItem("eventTracking", JSON.stringify(eventTracking));
	};

	// private method(s)
	const _constructor = async () => {
		// VARIABLE DEFINITIONS -------------------------------------|
		// const { pathname } = new URL(window.location.href);
		// const fullPageName = pathname.split('/').pop();
		// const pageName = fullPageName.split('.')[0];

		// const isProductPage =
		// 	document.querySelector('bat-producthero-zonnic') ||
		// 	document.querySelector('bat-productlisting-zonnic');

		// const isLoggedIn = checkLogin ? await checkLogin() : false;

		// const eventData = {
		// 	UserID: Utils.getLocalStorage('customer-id'),
		// 	PageType: pageName,
		// 	SystemType: isProductPage ? 'Nicotine Pouches' : null,
		// 	LoggedInStatus: isLoggedIn ? 'Logged In' : 'Logged Out',
		// 	Country: 'CA',
		// 	SiteSection: pageName,
		// };

		//get page
		const page = (window.BRAND && BRAND.getUrlPage()) || "";

		const eventData = {
			event : 'pageview',
    		PageType: page,
    		Country: (window.BRAND && BRAND.getUrlCountry()) || "",
    		SiteSection: page,
		};

		// FUNCTIONS ------------------------------------------------|
		_trackEvent(eventData);

		let eventTracking = sessionStorage.getItem("eventTracking");

		if (eventTracking) {
			try {
				eventTracking = JSON.parse(eventTracking);
			}
			catch(err) {
				console.log(err);
			}

			if (Array.isArray(eventTracking)) {
				eventTracking.forEach((eventData) => {
					_trackEvent(eventData);
				});

				sessionStorage.removeItem("eventTracking");
			}
		}

		/*
		ADOBE ANALYTICS
			let primeAcctNo = '';
			let profileCity = '';
			let profileState = '';
			let profileZip = '';
			if (isLoggedIn) {
				const credentials = await getAccessCredentials();
				const profile = await getAccessProfile();
				if (profile) {
					profileCity = profile['ns0:Address'][0]['ns0:City'];
					profileState = profile['ns0:Address'][0]['ns0:StateCode'];
					profileZip = profile['ns0:Address'][0]['ns0:ZipCode'];
				}
				primeAcctNo = credentials.PrimeAcctNo;
			}
			window.digitalData = {
				page: {
					brand: 'velo',
					pageName: document.title,
					section: pagename,
					subSection1: subSections[0] ? subSections[0] : '',
					subSection2: subSections[1] ? subSections[1] : '',
					subSection3: subSections[2] ? subSections[2] : '',
					pageType: 'cms',
					eCommAccess: '',
				},
			};
			digitalData.visitor = {
				userType: isLoggedIn ? 'authenticated' : 'guest',
				accountNo: primeAcctNo,
				customerId: primeAcctNo,
				city: profileCity,
				state: profileState,
				zip: profileZip,
				data: '',
			};
			// Page Load
			_trackEvent('page: track');
		*/

		_bindEvents();
	};

	_constructor();

	// output/public
	return {
		bindEvents: _bindEvents,
		trackEvent: _trackEvent,
		trackEventOnNextPageLoad: _trackEventOnNextPageLoad,
	};
})();
